<!-- eslint-disable eqeqeq -->
<!-- eslint-disable eqeqeq -->
<template>
  <b-card
    v-if="loaderData"
    class="text-center my-5"
  >
    <b-spinner
      type="grow"
      label="Loading..."
    />
  </b-card>
  <b-card v-else>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (En)"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="productForm.name_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (Ar)"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="productForm.name_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description (En)"
          >
            <b-form-group
              label="Description (En)"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="productForm.description_en"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description (Ar)"
          >
            <b-form-group
              label="Description (Ar)"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="productForm.description_ar"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Specification (En)"
          >
            <b-form-group
              label="Specification (En)"
              label-for="Specification"
            >
              <b-form-textarea
                id="Specification"
                v-model="productForm.specification_en"
                trim
                type="text"
                placeholder="Specification"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Specification (Ar)"
          >
            <b-form-group
              label="Specification (Ar)"
              label-for="Specification"
            >
              <b-form-textarea
                id="Specification"
                v-model="productForm.specification_ar"
                trim
                type="text"
                placeholder="Specification"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Quantity"
          >
            <b-form-group
              label="Quantity"
              label-for="Quantity"
            >
              <b-form-input
                id="Quantity"
                v-model.number="productForm.qty"
                trim
                type="number"
                placeholder="Quantity"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="price"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model.number="productForm.price"
              trim
              type="number"
              placeholder="price"
              @input="calculateDiscountedPrice(productForm.price,productForm.offer)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required|offer"
            name="offer"
          >
            <b-form-group
              label="Offer (in %)"
              label-for="Offer"
            >
              <b-form-input
                id="Offer"
                v-model.number="productForm.offer"
                trim
                type="number"
                placeholder="Offer (in %)"
                :state="getValidationState(validationContext)"
                @input="calculateDiscountedPrice(productForm.price,productForm.offer)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="price after discount"
          >
            <b-form-group
              label="Price after discount"
              label-for="price after discoun"
            >
              <b-form-input
                id="price after discount"
                v-model.number="productForm.net_price"
                trim
                :disabled="true"
                type="number"
                placeholder="Price after discount"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Countries"
          >
            <b-form-group
              label="Country"
              label-for="Countries"
            >
              <v-select
                v-model="productForm.country_id"
                :options="countries"
                :filterable="false"
                label="name"
                :reduce="(countries) => countries.id"
                :state="getValidationState(validationContext)"
                @search="searchCountries"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Featured"
            label-for="feathered"
          >
            <b-form-checkbox
              id="feathered"
              v-model="productForm.feathered"
              unchecked-value="0"
              value="1"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Active"
            label-for="Active"
          >
            <b-form-checkbox
              id="Active"
              v-model="productForm.active"
              unchecked-value="0"
              value="1"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Has Fees"
            label-for="Has Fees"
          >
            <b-form-checkbox
              id="Has Fees"
              v-model="productForm.has_fees"
              unchecked-value="0"
              value="1"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Is Voucher"
            label-for="Is Voucher"
          >
            <b-form-checkbox
              id="Is Voucher"
              v-model="productForm.is_voucher"
              :disabled="$route.params.id"
              unchecked-value="0"
              value="1"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          v-if="productForm.is_voucher == 1"
          md="6"
        >
          <b-form-group
            label="Split Voucher"
            label-for="Split Voucher"
          >
            <b-form-checkbox
              id="Split Voucher"
              v-model="productForm.is_voucher_split"
              :disabled="$route.params.id"
              unchecked-value="0"
              value="1"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <template v-if="productForm.is_voucher_split == 1 && productForm.is_voucher == 1">
          <b-col
            cols="12"
            class="d-flex justify-content-end "
          >
            <div>
              <b-button
                class="mb-1"
                variant="link"
                @click="addVoucherType()"
              >
                <feather-icon icon="PlusIcon" />
                Add Voucher Type
              </b-button>
            </div>
          </b-col>
          <b-row
            v-for="index in typesCount"
            :key="index"
            class="w-100 m-0"
          >
            <b-col cols="12">
              <div class="d-flex justify-content-between">
                <p class="primary--text">
                  Voucher type {{ index }}
                  <feather-icon
                    v-if="$route.params.id"
                    v-b-tooltip.hover
                    class="pointer"
                    title="Edit"
                    icon="EditIcon"
                    color="#f48a36"
                    size="20"
                    @click="openEditVoucherTypeDialog(voucherTypes[index - 1])"
                  />
                </p>

              </div>

            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                :name="`Voucher Type Name (En) ${index}`"
                rules="required"
              >
                <b-form-group
                  :label="`Type Name (En)` "
                  label-for="name"
                >
                  <b-form-input
                    :id="`Voucher Type Name (En) ${index}`"
                    v-model="voucherTypes[index - 1].name_en"
                    :state="getValidationState(validationContext)"
                    trim
                    :readonly="voucherTypes[index - 1].id!==undefined"
                    placeholder="Voucher Type Name (En) "
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                :name="`Voucher Type Name (Ar)`"
                rules="required"
              >
                <b-form-group
                  :label="`Type Name (Ar)` "
                  label-for="name"
                >
                  <b-form-input
                    :id="`Voucher Type Name (Ar) ${index}`"
                    v-model="voucherTypes[index - 1].name_ar"

                    :state="getValidationState(validationContext)"
                    trim
                    :readonly="voucherTypes[index - 1].id!==undefined"
                    placeholder="Voucher Type Name (Ar)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Is Used"
                label-for="Is Used"
              >
                <b-form-checkbox
                  :id="`Is Used${index}`"
                  v-model="voucherTypes[index - 1].is_used"
                  :disabled="$route.params.id"
                  unchecked-value="0"
                  value="1"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Show in portal"
                label-for="Show in portal"
              >
                <b-form-checkbox
                  :id="`Show in portal${index}`"
                  v-model="voucherTypes[index - 1].show_in_portal"
                  :disabled="$route.params.id"
                  unchecked-value="0"
                  value="1"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-media
                no-body
                class="mb-1"
              >
                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      v-img
                      :src="voucherTypes[index - 1].icon"
                      height="100"
                      width="100"
                    />
                  </b-link>
                <!--/ image -->
                </b-media-aside>

                <b-media-body>
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    :disabled="voucherTypes[index - 1].id!==undefined"

                    @click="selectIcon(index-1)"
                  >
                    Upload Icon (120 w X 120 h)
                  </b-button>
                  <b-form-file
                    :ref="`refIcon${index-1}`"
                    accept=".jpg, .png, .jpeg"
                    :hidden="true"
                    plain
                    @change="changeIcon($event, index-1)"
                  />
                <!--/ upload button -->
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </template>

        <b-col
          v-if="productForm.is_voucher == 1"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Partners"
          >
            <b-form-group
              label="Partner"
              label-for="Partners"
            >
              <v-select
                v-model="productForm.partner_id"
                :options="partners"
                :filterable="false"
                label="name"
                :reduce="(partners) => partners.id"
                :state="getValidationState(validationContext)"
                @search="searchPartners"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="productForm.is_voucher == 1"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Expired at"
            rules="required"
          >
            <b-form-group label="Expired at">
              <b-form-datepicker
                id="example-datepicker"
                v-model="productForm.expire_date"
                :state="getValidationState(validationContext)"
                class="mb-1"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="productForm.is_voucher == 1"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="voucher usage"
          >
            <b-form-group
              label="Voucher Usage (En)"
              label-for="voucher_usage"
            >
              <b-form-textarea
                id="voucher_usage"
                v-model="productForm.usage_en"
                trim
                type="text"
                placeholder="Voucher Usage"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="productForm.is_voucher == 1"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="voucher usage"
          >
            <b-form-group
              label="Voucher Usage (Ar)"
              label-for="voucher_usage"
            >
              <b-form-textarea
                id="voucher_usage"
                v-model="productForm.usage_ar"
                trim
                type="text"
                placeholder="Voucher Usage"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- <b-col cols="12">
          <vue-upload-multiple-image
            :data-images="images"
            :drag-text="'Select Products Images'"
            :browse-text="''"
            :popup-text="'This is the first image that will be shown in the products images'"
            :primary-text="'Primary image'"
            :mark-is-primary-text="'Select image as primary image'"
            @upload-success="uploadImageSuccess"
            @before-remove="beforeRemove"
            @edit-image="editImage"
          />
        </b-col> -->
        <b-col cols="12">
          <b-media>
            <b-row>
              <b-col
                v-for="(image, index) in productForm.product_images"
                :key="image.id"
                cols="3"
              >
                <div class="d-flex">
                  <span
                    class="deleteImgIcon"
                    @click="deleteImg(image.id, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </span>
                  <b-img
                    ref="previewEl"
                    v-img
                    :src="image.url"
                    class="ml-1 productImg"
                    height="auto"
                    width="100"
                  />
                </div>
              </b-col>
            </b-row>
            <!--/ image -->

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectFile()"
              >
                Upload Images
              </b-button>
              (1 : 0.75 aspect ratio)
              <validation-provider
                #default="validationContext"
                rules="required"
                name="product image"
              >
                <b-form-group>
                  <b-form-file
                    ref="refInputEl"
                    accept=".jpg, .png"
                    :hidden="true"
                    plain
                    multiple
                    :state="getValidationState(validationContext)"
                    @change="changeProfile($event)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="
              invalid ||
                Object.values(errors).length > 0 ||
                productForm.product_images.length === 0
            "
            @click="addProduct()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="
              invalid ||
                Object.values(errors).length > 0 ||
                product_images.length === 0
            "
            @click="addProduct()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    <div>
      <b-modal
        id="editVoucherType"
        ref="editVoucherType"
        hide-footer
        centered
        title="Edit Voucher Type"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>

            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Voucher Type Name"
                rules="required"
              >
                <b-form-group
                  label="Voucher Type Name (En)"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="voucherTypeEdit.name_en"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Voucher Type Name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Voucher Type Name"
                rules="required"
              >
                <b-form-group
                  label="Voucher Type Name (Ar)"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="voucherTypeEdit.name_ar"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Voucher Type Name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-media>

                <b-media-aside>

                  <div class="d-flex">

                    <b-img
                      ref="previewEl"
                      v-img
                      :src="voucherTypeEdit.icon"
                      class="ml-1 typeImg"
                      height="100"
                      width="100"
                    />

                  </div>

                  <!--/ image -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="selectVoucherIcon()"
                  >
                    Upload Icon
                  </b-button>
                  <validation-provider
                    #default="validationContext"
                    name="type image"
                  >
                    <b-form-group>
                      <b-form-file
                        ref="voucherIcon"
                        accept=".jpg, .png"
                        :hidden="true"
                        plain
                        multiple
                        :state="getValidationState(validationContext)"
                        @change="changeVoucherIcon($event) "
                      />
                    </b-form-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <!--/ upload button -->
                </b-media-body>
              </b-media>
            </b-col>

          </b-row></validation-observer>

        <b-col
          cols="12"
          md="12"
        >
          <b-button
            v-if="!loadereditVoucherType"
            variant="primary"
            class="mr-1"
            @click.prevent="editVoucherType"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeEditVoucherTypeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loadereditVoucherType"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import { extend } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
// import VueUploadMultipleImage from 'vue-upload-multiple-image'

extend('offer', {
  validate: value => value <= 100,
  message: 'Offer cannot be more than 100%.',
})
export default {
  components: {
    vSelect,
    // VueUploadMultipleImage,

  },
  data() {
    return {
      loaderData: false,
      id: this.$route.params.id,
      isVoucher: false,
      required,
      dropzoneOptions: {
        url: '',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
      },
      allSelected: [],
      indeterminate: false,
      loadereditVoucherType: false,
      countries: [],
      partners: [],
      users: [],
      images: [],
      icon: null,
      voucherTypeEdit:
          {
            name_en: '',
            name_ar: '',
            icon: null,

          },
      loader: false,
      validationErrors: {},
      errorMsg: '',
      typesCount: 2,
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const product_images = ref([])
    const voucherTypes = ref([
      {
        name_en: '',
        name_ar: '',
        is_used: 0,
        show_in_portal: 0,
        icon: null,
      },
      {
        name_en: '',
        name_ar: '',
        is_used: 0,
        show_in_portal: 0,
        icon: null,

      },
    ])
    const productForm = ref({
      product_images: [],
      is_voucher: 0,
      is_voucher_split: 0,
      feathered: 0,
      active: 0,
      has_fees: 0,
      offer: 0,
    })
    return {
      voucherTypes,
      getValidationState,
      productForm,
      product_images,
    }
  },
  mounted() {
    this.showProduct()
    this.getCountries()
    this.getPartners()
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },

    deleteImg(id, index) {
      if (id) {
        axios
          .get(`admin/delete_image/${id}`)
          .then(res => {
            if (res.status === 200) {
              this.showProduct()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Deleted Succesfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(() => {
            // this.formData = new FormData()
          })
          .finally(() => {
            this.loader = false
          })
      } else {
        this.productForm.product_images.splice(index, 1)
        this.product_images.splice(index, 1)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Succesfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }
    },
    deleteType(index) {
      if (this.typesCount > 2) {
        this.voucherTypes.splice(index, 1)
        this.typesCount -= 1
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'At Least Two Types is required',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      // console.log('data', formData, index, fileList)
      this.product_images = fileList
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const r = confirm('remove image')
      if (r === true) {
        done()
      }
    },
    // editImage(formData, index, fileList) {
    //   console.log('edit data', formData, index, fileList)
    // },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      // this.product_images = e.target.files[0]
      const input = e.target
      if (input.files) {
        for (let i = 0; i < input.files.length; i += 1) {
          const url = URL.createObjectURL(input.files[i])
          const img = new Image()
          img.src = window.URL.createObjectURL(input.files[i])

          img.onload = () => {
            const standard = 1 / 0.75
            const belowStandard = 1 / 0.80
            const userRatio = img.width / img.height

            if (userRatio <= standard && userRatio >= belowStandard) {
              if (input.files) {
                this.productForm.product_images.push({ url })
                this.product_images.push(input.files[i])
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Image Aspect Ratio is Not Valid',
                  icon: 'BellIcon',
                  variant: 'danger',
                  duration: 4000,

                },
              })
            }
          }
        }
      }
    },

    selectVoucherIcon() {
      this.$refs.voucherIcon.$el.click()
    },
    changeVoucherIcon(e) {
      // eslint-disable-next-line prefer-destructuring
      this.icon = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (img.width === 120 && img.height === 120) {
          if (input.files) {
            const reader = new FileReader()
            reader.onload = er => {
              this.voucherTypeEdit.icon = er.target.result
            }
            // eslint-disable-next-line prefer-destructuring
            this.icon = input.files[0]
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Icon Size is not valid',
              icon: 'BellIcon',
              variant: 'danger',
              duration: 4000,

            },
          })
        }
      }
    },
    async searchCountries(searchQuery) {
      await axios.get(`admin/countries?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    async searchPartners(searchQuery) {
      await axios.get(`admin/partners?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.partners = res.data?.data.partners
        }
      })
    },
    async getCountries() {
      await axios.get('admin/countries').then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    async getPartners() {
      await axios.get('admin/partners').then(res => {
        if (res.status === 200) {
          this.partners = res.data?.data.partners
        }
      })
    },
    closeEditVoucherTypeModal() {
      this.$refs.editVoucherType.hide()
    },
    openEditVoucherTypeDialog(voucherType) {
      this.voucherTypeEdit = voucherType
      this.$refs.editVoucherType.show()
    },
    addVoucherType() {
      this.voucherTypes.push({
        name_en: '',
        name_ar: '',
        is_used: 0,
        show_in_portal: 0,
        icon: null,
      })
      this.typesCount += 1
    },
    selectIcon(index) {
      this.$refs[`refIcon${index}`][0].$el.click()
    },

    changeIcon(e, index) {
      // eslint-disable-next-line prefer-destructuring
      this.images[index] = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (img.width === 120 && img.height === 120) {
          if (input.files) {
            const reader = new FileReader()
            reader.onload = er => {
              this.voucherTypes[index].icon = er.target.result
            }

            // eslint-disable-next-line prefer-destructuring
            this.images[index] = input.files[0]
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Icon Size is not valid',
              icon: 'BellIcon',
              variant: 'danger',
              duration: 4000,

            },
          })
        }
      }
      // img.onload = () => {
      //   if (input.files) {
      //     const reader = new FileReader()
      //     reader.onload = er => {
      //       this.voucherTypes[index].icon = er.target.result
      //     }
      //     // eslint-disable-next-line prefer-destructuring
      //     this.images[index] = input.files[0]
      //     reader.readAsDataURL(input.files[0])
      //   }
      // }
    },
    calculateDiscountedPrice(originalPrice, discountPercentage) {
      if (originalPrice > 0 && discountPercentage > 0 && discountPercentage <= 100) {
        const discountAmount = (originalPrice * discountPercentage) / 100
        const discountedPrice = originalPrice - discountAmount
        // Round to 2 decimal places
        this.productForm.net_price = parseFloat(discountedPrice.toFixed(2))
      } else {
        this.productForm.net_price = originalPrice
      }
    },
    showProduct() {
      if (this.$route.params.id) {
        this.loaderData = true

        axios.get(`admin/products/${this.$route.params.id}`).then(res => {
          this.productForm = res.data.data.product
          if (this.productForm.voucher_splits.length > 0) {
            this.voucherTypes = this.productForm.voucher_splits
            this.typesCount = this.productForm.voucher_splits.length
          } else {
            this.voucherTypes = [{
              name_en: '',
              name_ar: '',
              is_used: 0,
              show_in_portal: 0,
              icon: null,
            }, {
              name_en: '',
              name_ar: '',
              is_used: 0,
              show_in_portal: 0,
              icon: null,

            }]
            this.typesCount = 2
          }
        }).catch(() => {
        }).finally(() => {
          this.loaderData = false
        })
      } else {
        return false
      }
      return true
    },
    editVoucherType() {
      const formData = new FormData()
      this.loadereditVoucherType = true
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.voucherTypeEdit) {
        if (key !== 'icon') {
          formData.append(key, this.voucherTypeEdit[key])
        }
      }
      formData.delete('title')

      if (this.icon) {
        formData.append('icon', this.icon)
      }
      axios.post(`admin/update_voucher_split_type/${this.voucherTypeEdit.id}`, formData).then(res => {
        if (res.status === 200) {
          this.$refs.editVoucherType.hide()
          this.loadereditVoucherType = false
          this.showProduct()
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.loadereditVoucherType = false
      })
    },
    base64toBlob(base64) {
      const binaryString = window.atob(base64)
      const len = binaryString.length
      const bytes = new Uint8Array(len)

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }

      return new Blob([bytes])
    },
    addProduct() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.productForm) {
          if (key !== 'product_images') {
            formData.append(key, this.productForm[key])
          }
        }
        // eslint-disable-next-line eqeqeq
        if (this.productForm.is_voucher == 0) {
          formData.delete('partner_id')
        }
        formData.delete('voucher_splits')
        // eslint-disable-next-line eqeqeq
        if (this.productForm.is_voucher_split == 1) {
          // eslint-disable-next-line no-restricted-syntax
          for (let index = 0; index < this.typesCount; index += 1) {
            if (this.voucherTypes[index].id === undefined) {
              formData.append(`voucher_splits[${index}][name_ar]`, this.voucherTypes[index].name_ar)
              formData.append(`voucher_splits[${index}][name_en]`, this.voucherTypes[index].name_en)
              formData.append(`voucher_splits[${index}][is_used]`, this.voucherTypes[index].is_used)
              formData.append(`voucher_splits[${index}][show_in_portal]`, this.voucherTypes[index].show_in_portal)
              formData.append(`voucher_splits[${index}][icon]`, this.images[index])
            }
          }
        }
        if (this.product_images) {
          // eslint-disable-next-line no-restricted-syntax
          for (const image of this.product_images) {
            formData.append('images[]', image)
          }
          // this.product_images.forEach(imageBase64 => {
          //   const blob = this.base64toBlob(imageBase64.path)
          //   formData.append('images[]', blob)
          // })
        }
        axios
          .post(`admin/products/${this.$route.params.id}`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'products' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status === 500) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ooops!!! An Unexpected Internal server error',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            } else if (error.response.status === 422) {
              if (error.response.data.errors !== undefined) {
                this.errorMsg = error.response.data.errors
              } else {
                this.errorMsg = error.response.data.message
              }
            }
          })
          .finally(() => {
            this.loader = false
          })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.productForm) {
          if (key !== 'product_images') {
            formData.append(key, this.productForm[key])
          }
        }
        if (this.product_images) {
          // eslint-disable-next-line no-restricted-syntax
          for (const image of this.product_images) {
            formData.append('images[]', image)
          }
        }
        // eslint-disable-next-line eqeqeq
        if (this.productForm.is_voucher_split == 1) {
          // eslint-disable-next-line no-restricted-syntax
          for (let index = 0; index < this.typesCount; index += 1) {
            formData.append(`voucher_splits[${index}][name_ar]`, this.voucherTypes[index].name_ar)
            formData.append(`voucher_splits[${index}][name_en]`, this.voucherTypes[index].name_en)
            formData.append(`voucher_splits[${index}][is_used]`, this.voucherTypes[index].is_used)
            formData.append(`voucher_splits[${index}][show_in_portal]`, this.voucherTypes[index].show_in_portal)
            formData.append(`voucher_splits[${index}][icon]`, this.images[index])
          }
        }
        axios
          .post('admin/products', formData)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              this.$router.push({ name: 'products' })
              this.$toasted.show('Created Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status === 500) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ooops!!! An Unexpected Internal server error',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            } else if (error.response.status === 422) {
              if (error.response.data.errors !== undefined) {
                this.errorMsg = error.response.data.errors
              } else {
                this.errorMsg = error.response.data.message
              }
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.deleteImgIcon {
  cursor: pointer;
  color: grey;
}
.image-container {
    width: 100% !important;
    height: 300px !important;
}
.image-list-container .image-list-item{
  height: 160px !important;
    width: 160px !important;
}
.image-list-container .image-list-item .show-img{
  max-height: 150px !important;
    max-width: 150px !important;
}
.image-list-container{
  max-width: 100% !important;
}
.show-img {
    max-height: 200px !important;
    max-width: 350px !important;
    display: block;
    vertical-align: middle;
    margin-top: 16px;
}
.preview-image{
  max-height: 200px !important;

}
</style>
